type BuildAuthorNameInput = {
	firstname?: string | null;
	lastname?: string | null;
} | null;

const buildAuthorName = (author?: BuildAuthorNameInput) => {
	if (!author) {
		return null;
	}

	const firstName = !!author.firstname ? author.firstname.trim() : null;
	const lastName = !!author.lastname ? author.lastname.trim() : null;

	if (!!firstName && !!lastName) {
		return `${firstName} ${lastName}`;
	}

	if (!!firstName) {
		return firstName;
	}

	if (!!lastName) {
		return lastName;
	}

	return null;
};

export default buildAuthorName;
