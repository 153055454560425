import { ImageObject, NewsMediaOrganization } from 'schema-dts';

function BRLogo(appUrl: string): ImageObject {
	return {
		'@type': 'ImageObject',
		url: `${appUrl}/assets/BR24_logo.png`,
		height: { '@type': 'QuantitativeValue', value: 100, unitCode: 'px' },
		width: { '@type': 'QuantitativeValue', value: 100, unitCode: 'px' },
	};
}

export function publisher(appUrl: string): Exclude<NewsMediaOrganization, string> {
	return {
		'@type': 'NewsMediaOrganization',
		name: 'BR24',
		logo: BRLogo(appUrl),
		url: 'https://www.br.de',
		foundingDate: '1949-01-25',
		sameAs: [
			'https://www.facebook.com/BR24/',
			'https://twitter.com/BR24',
			'https://www.instagram.com/br24/',
			'https://www.youtube.com/channel/UCcweJsCV2TUP_kzMX25U-oQ',
			'https://de.linkedin.com/company/bayerischer-rundfunk',
			'https://de.wikipedia.org/wiki/BR24',
			'https://www.pinterest.de/BR24news/',
		],
	};
}

export function organization(appUrl: string) {
	return {
		'@id': 'https://www.br.de/#publisher',
		...publisher(appUrl),
	};
}

export function defaultImage(appUrl: string): ImageObject {
	return {
		'@type': 'ImageObject',
		caption: 'Nachrichten, Fakten und Hintergrundinformationen zu Politik, Wirtschaft, Kultur und Sport von BR24.',
		height: { '@type': 'QuantitativeValue', value: 450, unitCode: 'px' },
		width: { '@type': 'QuantitativeValue', value: 800, unitCode: 'px' },
		url: 'https://img.br.de/70ba39af-8347-4e2b-a292-ac4bd6d4368c.png?w=800&h=450',
		copyrightHolder: organization(appUrl),
	};
}
